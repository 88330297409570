import { notDesktop, styled, Text } from "@obvio/app";
import { Stack } from "@obvio/ui";
import { motion } from "framer-motion";
import { Fragment } from "react";

import type { ReactElement } from "react";

type TextData =
  | {
    title: string;
    template: "bigSmall";
    data: { textBig: string; textSmall: string };
  }
  | {
    title: string;
    template: "smallBig";
    data: { textSmall: string; textBig: string };
  }
  | { title: string; template: "big"; data: { textBig: string } };

type TextFlowProps = {
  texts: TextData[];
};

const BigText = styled(Text)`
  font-size: min(7.5rem, 6vw);
`;

const SmallText = styled(Text)`
  width: 15rem;
`;

const Wrap = motion(styled(Stack)`
  align-items: center;
`);

const Desktop = motion(styled(Stack)`
  @media ${notDesktop} {
    display: none;
  }
`);

const MobileTexts = styled.div`
  display: none;
  @media ${notDesktop} {
    display: flex;
    flex-direction: column;
  }
`;

type Splitted = { big: string[]; small: string[] };

const splitTexts = (texts: TextData[]): Splitted =>
  texts.reduce<Splitted>(
    (acc, text) => {
      const { textBig, textSmall } = text.data as {
        textBig: string;
        textSmall?: string;
      };
      if (textBig) {
        acc.big.push(textBig);
      }
      if (textSmall) {
        acc.small.push(textSmall);
      }
      return acc;
    },
    { big: [], small: [] },
  );

export function TextFlow({ texts }: TextFlowProps): ReactElement {
  const splitted = splitTexts(texts);
  return (
    <>
      <Desktop
        kind="vertical"
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        transition={{
          when: "beforeChildren",
          staggerChildren: 0.3,
          delayChildren: 0.35,
        }}
      >
        {texts.map((text) => (
          <Wrap
            key={text.title}
            spacing="medium"
            variants={{
              initial: { opacity: 0, y: 10 },
              animate: { opacity: 1, y: 0 },
            }}
            transition={{
              duration: 0.75,
            }}
          >
            {text.template === "bigSmall" ? (
              <>
                <BigText tag="span" as="h2">
                  {text.data.textBig}
                </BigText>
                <SmallText tag="span">{text.data.textSmall}</SmallText>
              </>
            ) : text.template === "smallBig" ? (
              <>
                <SmallText tag="span">{text.data.textSmall}</SmallText>
                <BigText tag="span" as="h2">
                  {text.data.textBig}
                </BigText>
              </>
            ) : (
              <BigText tag="span" as="h2">
                {text.data.textBig}
              </BigText>
            )}
          </Wrap>
        ))}
      </Desktop>
      <MobileTexts>
        {splitted.small.map((text, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={String(idx)}>
            <Text>{text}</Text>
            <br />
          </Fragment>
        ))}
        <BigText tag="span" as="h2">
          {splitted.big.join(" ")}
        </BigText>
      </MobileTexts>
    </>
  );
}
